import {
  fetchingDataWithAxiosMiddleware,
  set_response_notification,
} from "./commonAction";
import apiService from "../../serviceUtils";
import { get_vendors } from "./vendor";
import { set_auth_info } from "./auth";

export const save_credit_card = (data) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("cardNumber", `${data.cardNumber}`);
    formData.append("nameOnCard", `${data.nameOnCard}`);
    formData.append("expirationMonth", `${data.expirationMonth}`);
    formData.append("expirationYear", `${data.expirationYear}`);
    formData.append("cvc", `${data.cvv}`);
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${apiService.save_credit_card()}`,
      formData
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(set_auth_info(data));
        dispatch(
          set_response_notification({ type: "success", text: "Success" })
        );
      }
    }
  } catch (e) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Warning ->  " + e.message,
      })
    );
  }
};
export const delete_credit_card = (id) => async (dispatch) => {
  try {
    const response = await fetchingDataWithAxiosMiddleware(
      "DELETE",
      `${apiService.delete_credit_card()}/${id}`
    );
    if (response?.status) {
      await response.data;
      dispatch(get_vendors());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text:
          "Warning ->  " +
          (!!error.response
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : error.message),
      })
    );
  }
};
