import {
  fetchingDataWithAxiosMiddleware,
  set_loading,
  set_response_notification,
  setInProgressMaterialLoading,
  setServerIsDown,
} from "./commonAction";
import Services from "../../Services";

export const SET_RUGS = "SET RUGS";

export const setRugs = (payload) => {
  return {
    type: SET_RUGS,
    payload,
  };
};

export const getAllRugs = (filterData) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "rugs", value: true }));
    const params = filterData
      ? filterData
      : JSON.parse(sessionStorage.getItem("filterParams")).rugs;
    const response = await fetchingDataWithAxiosMiddleware(
      "GET",
      `${Services.Rug.getAllURL(params)}`
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(setRugs(Services.Rug.handlerData(data)));
      } else if (response.status === 204) {
        dispatch(setRugs(Services.Rug.handlerData([])));
      }
    }
    dispatch(set_loading({ type: "rugs", value: false }));
  } catch (error) {
    dispatch(set_loading({ type: "rugs", value: false }));
    dispatch(
      set_response_notification({
        type: "error",
        text:
          "Warning ->  " +
          (!!error.response
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : error.message),
      })
    );
  }
};

export const addRug = (payload) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "rugs", value: true }));
    const formData = new FormData();
    formData.append("name", payload.name.trim());
    formData.append("vendorID", payload.vendorID);
    formData.append("urlOnVendorPage", payload.url);
    formData.append("skuNumber", payload.skuNumber);
    formData.append("height", payload.height);
    formData.append("price", payload.price);
    formData.append("width", payload.width);
    formData.append("productImage", payload.material);
    // formData.append("productImageURL", "https://t3.ftcdn.net/jpg/00/92/53/56/240_F_92535664_IvFsQeHjBzfE6sD4VHdO8u5OHUSc6yHF.jpg")

    await fetchingDataWithAxiosMiddleware(
      "POST",
      Services.Rug.changeURL(),
      formData
    );

    dispatch(set_response_notification({ type: "success", text: "Success" }));
    dispatch(getAllRugs());
    return dispatch(set_loading({ type: "rugs", value: false }));
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Warning -> " + error.message,
      })
    );
  }
};

export const editRug = (payload) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "rugs", value: true }));
    const formData = new FormData();
    payload.name && formData.append("name", payload.name.trim());
    payload.vendorID && formData.append("vendorID", payload.vendorID);
    payload.url && formData.append("urlOnVendorPage", payload.url);
    payload.skuNumber && formData.append("skuNumber", payload.skuNumber);
    payload.height && formData.append("height", payload.height);
    payload.price && formData.append("price", payload.price);
    payload.width && formData.append("width", payload.width);
    payload.material && formData.append("productImage", payload.material);

    await fetchingDataWithAxiosMiddleware(
      "PUT",
      Services.Rug.changeURL(payload.id),
      formData
    );
    dispatch(set_response_notification({ type: "success", text: "Success" }));
    dispatch(getAllRugs());
    return dispatch(set_loading({ type: "rugs", value: false }));
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Warning -> " + error.message,
      })
    );
    return dispatch(set_loading({ type: "rugs", value: false }));
  }
};

export const deleteRug = (id) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "rugs", value: true }));
    await fetchingDataWithAxiosMiddleware("DELETE", Services.Rug.changeURL(id));
    dispatch(getAllRugs());
    dispatch(set_response_notification({ type: "success", text: "Success" }));
    return dispatch(set_loading({ type: "rugs", value: false }));
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Warning -> " + error.message,
      })
    );
    return dispatch(set_loading({ type: "rugs", value: false }));
  }
};

export const prepoccessRug = (id) => async (dispatch) => {
  try {
    dispatch(setInProgressMaterialLoading({ type: "rugs", value: id }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${Services.Rug.rugPreproccess(id)}`
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(getAllRugs());
        dispatch(
          set_response_notification({ type: "success", text: "Success" })
        );
      }
    }
    dispatch(setInProgressMaterialLoading({ type: "rugs", value: false }));
  } catch (error) {
    dispatch(setInProgressMaterialLoading({ type: "rugs", value: false }));
    dispatch(
      set_response_notification({
        type: "error",
        text:
          "Warning ->  " +
          (!!error.response
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : error.message),
      })
    );
  }
};

export const pause_rug = (data) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "rugs", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${Services.Rug.changeURL()}/pause/${data}`
    );
    if (response?.status) {
      dispatch(set_response_notification({ type: "success", text: "Success" }));
      dispatch(getAllRugs());
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text:
          "Warning ->  " +
          (!!error.response
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : error.message),
      })
    );
    dispatch(set_loading({ type: "rugs", value: false }));
  }
};
export const transform_rug = (data) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("decorID", data.decorID);
    formData.append("scale", data.scale);
    dispatch(set_loading({ type: "rugs", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${Services.Rug.changeURL()}/transform-rugs/`,
      formData
    );
    if (response?.status) {
      dispatch(getAllRugs());
      dispatch(set_loading({ type: "rugs", value: false }));
      dispatch(set_response_notification({ type: "success", text: "Success" }));
    }
  } catch (error) {
    if (error.message === "Network Error") {
      dispatch(setServerIsDown(true));
    }
    dispatch(
      set_response_notification({
        type: "error",
        text:
          "Warning ->  " +
          (!!error.response
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : error.message),
      })
    );
    dispatch(set_loading({ type: "rugs", value: false }));
  }
};
